import React from "react";

const ContComponent = () => (
  <div className="mb_cont intro2">
    <div className="mb_contIn">
      <div className="vs">
        <img src="/img/sub/intro2_vs.png" alt="TIGERS" className="pc_tb" />
        <img src="/img/sub/intro2_vs_mb.png" alt="TIGERS" className="mb" />
        <h3>
          <strong>BASEBALL CLUB</strong> KIA타이거즈
        </h3>
        <h4>한국시리즈 12번 진출, 12번 우승 '불패 신화'</h4>
        <h4>한국 프로야구를 대표하는 최고의 명문 구단</h4>
        
      </div>
      <article>
        <div>
          <img
            src="/img/sub/tigers1.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>프로야구 최강자 입증, 'V12'  불멸의 기록 '무적 호랑이'</h5>
          <p>
          KIA타이거즈가 2024년 통합우승을 일궈내며 또 다시 한국 프로야구사를 새롭게 작성했습니다. 12번 한국시리즈에 진출해 12번 모두 우승이라는 '불패 신화'를 써 내려간 것입니다. 지난 2009년, 2017년에 이어 2024년에도 정규리그 1위, 한국시리즈 우승을 차지, 통합 우승의 쾌거를 달성하며 한국 프로야구 최강자임을 입증했습니다.
            <br />
            <br />
            2024년 통합우승 최고의 감격은 안방에서 트로피를 들어 올렸다는 것입니다. 지난 1987년 무등경기장에서의 환희가 37년 만에 또 다시 광주 팬들의 박수와 함성으로 가득찬 홈구장에서 재현된 것입니다.
            <br />
            <br />
            KIA타이거즈의 한국시리즈 불패신화는 영원할 것입니다. 1983년 프로야구 출범 2년째를 맞아 한국시리즈에서 첫 우승을 달성한 이후, 86년부터 89년까지 4년간 한국시리즈 불패의 신화를 이룩했습니다. KIA타이거즈의 한국시리즈 불패 신화는 여기서 그치지 않았습니다. 90년 잠시 숨을 고른 후 91년과 93년 또 다시 한국시리즈를 제패하는 투지를 불태웠고, 이후 96년과 97년 2년 연속 챔피언에 오르며 한국 프로야구의 최강자임을 팬들에게 각인 시켰습니다.
          </p>
        </div>
      </article>
      <article>
        <div>
          <img
            src="/img/sub/tigers2.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>지도자-선수-프런트 혼연일체 ‘최고의 팀워크’...스타의 산실</h5>
          <p>
          KIA타이거즈는 해태타이거즈를 인수한 후 지난 2001년 8월 2일 인천에서 SK와이번스와 역사적인 첫 경기를 가지며 그 출범을 알렸습니다. 이후 KIA타이거즈는 과거의 훌륭한 전통과 미래 모빌리티 시장에서 글로벌 톱티어 기업으로 위상을 강화하고 있는 현대자동차그룹의 노하우를 접목시키며 한국 최고의 명문구단으로 그 위상을 확고히 하고 있습니다.
            <br />
            <br />
            무엇보다 구단 최고의 리더십 아래 선수단과 지도자, 프런트가 혼연일체가 되어 끈끈한 팀워크로 그라운드를 압도하고 있습니다. 최고의 팀이라는 자긍심과 자부심. 그러나 자만하지 않는 KIA타이거즈 구성원들은 정상을 지키기 위해 매 순간 최선을 다 할 것입니다.
            <br />
            <br />
            KIA타이거즈는 한국야구 스타들의 요람입니다. 김봉연, 김성한, 김종모, 이순철, 한대화, 선동열, 이강철, 이종범, 이대진, 장성호 등 90년대를 대표하는 프로야구 스타들이 모두 호랑이 유니폼을 입고, 그라운드를 누비며 팬들의 환호를 받았습니다. 이후 2000년대에 접어들어 이범호, 김주찬, 최형우, 윤석민, 나지완, 양현종, 나성범, 김선빈 등으로 스타 명맥이 이어졌습니다. 마침내 2024년 KIA타이거즈는 한국 프로야구를 대표하는 최고의 스타를 탄생시켰습니다. 김도영. 온갖 대기록을 남긴 그의 역동적인 모습에서 KIA타이거즈이 미래를 가늠할 수 있습니다.
          </p>
        </div>
      </article>
      <article>
        <div>
          <img
            src="/img/sub/tigers3.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>Always KIA TIGERS...팬들과 함께한 영광의 역사</h5>
          <p>
          V12의 역사는 팬들과 함께한 영광의 역사였습니다. 전국 어디서나 애정 가득한 응원을 보내주신 팬 여러분들의 격려가 있었기에 '한국시리즈 12번 우승'이라는 불멸의 금자탑을 쌓을 수 있었습니다.
            <br />
            <br />
            팬들과 함께 우승의 기쁨을 누리기 위해 선수단은 압도적인 퍼포먼스를 보여주었고, 프런트는 전폭적인 지원을 아끼지 않았습니다. 국내 그 어느 구단보다 애정 가득한 응원을 보내주시는 팬들이 있기에 오늘의 KIA타이거즈가 존재한다고 해도 과언이 아닐 것입니다.
            <br />
            <br />
            KIA타이거즈는 팬들이 진정 원하는 것이 무엇인지 정확히 헤아릴 줄 아는 구단으로 성장하기 위해 부단한 노력을 기울이고 있습니다. 선수단은 매 경기 최선을 다하는 플레이로, 프런트는 꾸준한 성적과 다채로운 팬서비스를 지속 시킬 수 있는 시스템 구축으로 팬들의 기대에 부응할 것입니다. 팬들에게 즐거움을 드리기 위한 KIA타이거즈의 쉼없는 노력은 계속 될 것입니다.
          </p>
        </div>
      </article>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
