import React from "react";
import * as CommonHelper from "../../../helpers/Common.helper";
import PrepareComponent from '../../layout/Prepare.component'


const ContComponent = (props) => {
  
  return(
  <div className="mb_cont ticket">
    <div className="mb_contIn">
      {props.imgUrlObj &&
        (CommonHelper.checkIsOnWebview() ? (
          <p className="img">
            <img src={props.imgUrlObj.pc} alt="" className="pc_tb" />
            <img src={props.imgUrlObj.m} alt="" className="mb" />
          </p>
        ) : (
          <>
            <p className="img">
              <img
                src={
                  props.tabNum === 2
                    ? "/img/main/kid_membership_end_2022.png"
                    : props.imgUrlObj.pc
                }
                alt="t-club 멤버십"
                // style={{ marginBottom: "100px" }}
              />
            </p>
            {/* <p className="img">
              <img
                src={
                  props.tabNum === 2
                    ? "/img/main/kid_membership_2022_04.jpg"
                    : props.imgUrlObj.pc
                }
                alt="t-club 멤버십"
                style={{ marginBottom: "100px" }}
              />
            </p> */}
            {props.tabNum === 2 && (
              <div className="btn_area">
                <button
                  className="pc_tb kid_membership end"
                  onClick={() =>
                    //   window.open(
                    //     "http://www.ticketlink.co.kr/sports/baseball/event/330",
                    //     "_blank"
                    //   )

                    alert("가입이 마감되었습니다.")
                  }
                >
                  가입마감
                </button>

                <button
                  className="mb kid_membership end"
                  onClick={() =>
                    // window.open(
                    //   "http://www.ticketlink.co.kr/sports/baseball/event/330",
                    //   "_blank"
                    // )

                    alert("가입이 마감되었습니다.")
                  }
                >
                  가입마감
                </button>
              </div>
            )}
          </>
        ))}
    </div>
  </div>
)}

export default ContComponent;
