import React, {Component} from "react"
import {withRouter, Link} from "react-router-dom"
import cookie from "react-cookies"

import SlideBannerPcComponent from "./SlideBannerPc.component"
import SlideBannerMobileComponent from "./SlideBannerMobile.component"
import SlideGameComponent from "./SlideGame.component"
import SlideHotIssueComponent from "./SlideHotIssue.component"
import SlidePartnerComponent from "./SlidePartner.component"
import SlideAdComponent from "./SlideAd.component"
import LayerPopupComponent from "./LayerPopup.component"

import * as ArticleService from "../../services/Article.service"
import * as ContentService from "../../services/Content.service"
import * as GameService from "../../services/Game.service"
import * as CommonHelper from "../../helpers/Common.helper"
import * as DateHelper from "../../helpers/Date.helper"
import AnalyticsContext from "../../context/AnalyticsContext"
import * as CommonService from "../../services/Common.service"

class HomeContainer extends Component {
  static contextType = AnalyticsContext

  constructor(props) {
    super(props)

    this.state = {
      bannerListObj: null,
      recentlyGamesList: [],
      hotIssueList: [],
      allTigers: {},
      sponsorList: [],
      snsInfo: {},
      popup: false,
      popupContentList: []
    }
  }

  closePopup = (evt) => {
    switch (evt.target.className) {
      case "close":
        break
      case "one_day_close":
        cookie.save("popup", "close", {
          path: "/",
          maxAge: 24 * 60 * 60
        })
        break
      default:
        break
    }

    this.setState({popup: false})
    // cookie.save("popup", "close", { path: "/", maxAge: 24 * 60 * 60 * 1000 });
    // this.setState({ popup: false });
  }

  componentDidMount = async (_) => {
    // window.open(
    //   "/popup/",
    //   "_blank",
    //   "width=620; height=355; left=",
    //   "scrollbars=no, resizable=no, toolbars=no, menubar=no" +
    //     (window.screen.availWidth - 620) / 2 +
    //     "; top=" +
    //     (window.screen.availHeight - 355) / 2 +
    //     ";"
    // );

    try {
      //Analytics
      const currentAnalyticsVal = this.context.state
      const prevUrl = currentAnalyticsVal.currentUrl
      this.context.action(currentAnalyticsVal)

      currentAnalyticsVal.prevUrl = prevUrl
      currentAnalyticsVal.currentUrl = window.location.href

      await CommonService.insertAnalytics(this.context.state)

      // 메인 배너
      // const bannerListResult = await ContentService.getMainBannerList();
      // const bannerList = bannerListResult.list;

      // 최신 게임
      let recentlyGamesResult = await GameService.getMajorRecentlyGames()
      let popupContentList = await ContentService.getPopupContents()

      this.setState({popupContentList: popupContentList.list})

      let recentlyGamesList = []
      Object.keys(recentlyGamesResult).map((key, idx) => {
        let recentlyGame = recentlyGamesResult[key]
        recentlyGame["formattedGameDate"] = DateHelper.makeSeparatedDate(recentlyGame["displayDate"], ".")
        if (recentlyGame["outcome"] === "") {
          recentlyGame["statusStr"] = "경기 전"
        } else if (recentlyGame["outcome"] === "취") {
          recentlyGame["statusStr"] = "경기취소"
        } else if (recentlyGame["outcome"] === "중") {
          recentlyGame["statusStr"] = "경기 중"
        } else {
          recentlyGame["statusStr"] = "경기종료"
        }

        key === "next"
          ? (recentlyGamesList[2] = recentlyGame)
          : key === "current"
          ? (recentlyGamesList[1] = recentlyGame)
          : (recentlyGamesList[0] = recentlyGame)
      })

      // Hot Issue Result
      const hotIssueResult = await ArticleService.getHotIssueList()
      let hotIssueList = hotIssueResult.list
      for (let i = 0; i < hotIssueList.length; i++) {
        hotIssueList[i]["boardName"] = CommonHelper.getBoardNameByBoardCode(hotIssueList[i]["boardCode"])
        hotIssueList[i]["regDate"] = DateHelper.convertTimestampToDate(hotIssueList[i]["regDttm"])
      }

      // All Tigers
      // const allTigersResult = await ContentService.getAllTigers();
      // const allTigers = allTigersResult.contents;

      // Main Banner
      let bannerListResult = await ContentService.getBannerList()
      let bannerList = bannerListResult.list
      let bannerListObj = {}
      for (let i = 0; i < bannerList.length; i++) {
        const bannerType = bannerList[i]["type"]
        if (bannerType === "TOPRIGHT" || bannerType === "MAIN") {
          if (!bannerListObj[bannerType]) {
            bannerListObj[bannerType] = []
          }
          bannerListObj[bannerType].push(bannerList[i])
        } else {
          bannerListObj[bannerType] = bannerList[i]
        }
      }

      console.log("bannerListObj", bannerListObj)

      // SNS
      let snsInfo = await ContentService.getSnsInfo()
      if (snsInfo["youtube"]) {
        snsInfo["youtube"]["url"] = `https://www.youtube.com/embed/${snsInfo["youtube"]["url"].split("=")[1]}`
      }

      const sponsorResult = await ArticleService.getSponsorList()
      const sponsorList = sponsorResult.list.concat(sponsorResult.list)

      const popup = cookie.load("popup") !== "close"

      this.setState({
        bannerListObj,
        recentlyGamesList,
        hotIssueList,
        snsInfo,
        sponsorList,
        popup
      })
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  setSliderRef = (type, ref) => {
    if (type === "slide-banner-pc") {
      this.slideBannerPc = ref
    } else if (type === "slide-banner-mobile") {
      this.slideBannerMobile = ref
    } else if (type === "slide-game") {
      this.slideGame = ref
    } else if (type === "slide-hot-issue") {
      this.slideHotIssue = ref
    } else if (type === "slide-partner") {
      this.slidePartner = ref
    }
  }
  onClickBtnSlidePrev = (evt, type) => {
    if (type === "slide-banner-pc") {
      this.slideBannerPc.slickPrev()
    } else if (type === "slide-banner-mobile") {
      this.slideBannerMobile.slickPrev()
    } else if (type === "slide-game") {
      this.slideGame.slickPrev()
    } else if (type === "slide-hot-issue") {
      this.slideHotIssue.slickPrev()
    } else if (type === "slide-partner") {
      this.slidePartner.slickPrev()
    }
  }
  onClickBtnSlideNext = async (evt, type) => {
    if (type === "slide-banner-pc") {
      this.slideBannerPc.slickNext()
    } else if (type === "slide-banner-mobile") {
      this.slideBannerMobile.slickNext()
    } else if (type === "slide-game") {
      this.slideGame.slickNext()
    } else if (type === "slide-hot-issue") {
      this.slideHotIssue.slickNext()
    } else if (type === "slide-partner") {
      this.slidePartner.slickNext()
    }
  }

  onClickBtnHotIssue = (evt, boardCode, artcSeq) => {
    let uri = `${CommonHelper.getUriByCategoryCode(boardCode)}/${artcSeq}?fromHome=y`
    this.props.history.push(uri)
  }

  onClickBtnCm = (_) => {
    window.open("https://www.kia.com/kr/main.html")
  }

  onClickBtnLink = (evt, link) => {
    window.open(link)
  }

  render = () => (
    <div className="container">
      {this.state.bannerListObj ? (
        <div className="contIn">
          {/* carousel banner  begin*/}
          {/* PC */}
          <SlideBannerPcComponent
            list={this.state.bannerListObj["MAIN"]}
            setSliderRef={this.setSliderRef}
            onClickBtnSlidePrev={this.onClickBtnSlidePrev}
            onClickBtnSlideNext={this.onClickBtnSlideNext}
          />
          {/* Mobile */}
          <SlideBannerMobileComponent list={this.state.bannerListObj["MAIN"]} setSliderRef={this.setSliderRef} />
          {/* carousel banner  end*/}

          {/* Current Game begin */}
          
          {/* <!-- 231018 홈 스코어보드 주석 시작 --> */}
          {process.env.REACT_APP_PREPARING_GAME_PAGE_YN === "Y" ? null : (
            <SlideGameComponent
              list={this.state.recentlyGamesList}
              setSliderRef={this.setSliderRef}
              onClickBtnSlidePrev={this.onClickBtnSlidePrev}
              onClickBtnSlideNext={this.onClickBtnSlideNext}
            />
          )}
          {/* <!-- 231018 홈 스코어보드 주석 끝 --> */}

          {/* Current Game End */}

          {/* Hot issue Begin */}
          <SlideHotIssueComponent
            list={this.state.hotIssueList}
            onClickBtnHotIssue={this.onClickBtnHotIssue}
            setSliderRef={this.setSliderRef}
            onClickBtnSlidePrev={this.onClickBtnSlidePrev}
            onClickBtnSlideNext={this.onClickBtnSlideNext}
          />
          {/* Hot issue End */}

          {/* All Tigers */}
          <section className="aTiger m0auto clear">
            <h3>
              ALL <span>TIGERS</span>
            </h3>
            {/* TOPLEFT */}
            <div className="mainKia_tigers">
              <Link to={this.state.bannerListObj["TOPLEFT"].link}>
                <img
                  src={this.state.bannerListObj["TOPLEFT"]["pcImgFilePath"]}
                  alt="KIA TIGERS 멤버십안내, 어린이 멤버십 / 성인 멤버십, 기아타이거즈 다양한 혜택을 만나보세요."
                  className="pc"
                />
                <img
                  src={this.state.bannerListObj["TOPLEFT"]["mobileImgFilePath"]}
                  alt="KIA TIGERS 멤버십안내, 어린이 멤버십 / 성인 멤버십, 기아타이거즈 다양한 혜택을 만나보세요."
                  className="tb"
                />
              </Link>
            </div>
            {/* TOP */}
            <div className="mainTg_shop">
              <a href={this.state.bannerListObj["TOP"].link} target="_blank" rel="noopener noreferrer">
                <img
                  src={this.state.bannerListObj["TOP"]["pcImgFilePath"]}
                  alt="TIGERS SHOPPING MALL,다양한 기아타이거즈 용품을 만나보세요."
                  className="pc"
                />
                <img
                  src={this.state.bannerListObj["TOP"]["mobileImgFilePath"]}
                  alt="TIGERS SHOPPING MALL,다양한 기아타이거즈 용품을 만나보세요."
                  className="tb"
                />
              </a>
            </div>

            {/* TOPRIGHT */}
            <SlideAdComponent list={this.state.bannerListObj["TOPRIGHT"]} onClickBtnLink={this.onClickBtnLink} />

            {/* LEFT */}
            <div className="mainGame_date">
              <Link to={this.state.bannerListObj["LEFT"].link}>
                <img src={this.state.bannerListObj["LEFT"]["pcImgFilePath"]} alt="경기일정, 기아티아이거즈와 함께 응원하자!" className="pc" />
                <img src={this.state.bannerListObj["LEFT"]["mobileImgFilePath"]} alt="경기일정, 기아티아이거즈와 함께 응원하자!" className="tb" />
              </Link>
            </div>

            {/* CENTER */}
            <div className="mainKia_map">
              <Link to={this.state.bannerListObj["CENTER"].link}>
                <img src={this.state.bannerListObj["CENTER"]["pcImgFilePath"]} alt="KIA Champions Field 오시는길" className="pc" />
                <img src={this.state.bannerListObj["CENTER"]["mobileImgFilePath"]} alt="KIA Champions Field 오시는길" className="tb" />
              </Link>
            </div>

            {/* RIGHT */}
            <div className="mainPlayer">
              <Link to={this.state.bannerListObj["RIGHT"].link}>
                <img src={this.state.bannerListObj["RIGHT"]["pcImgFilePath"]} alt="선수소개, 기아티아이거즈의 호랑이 전사들!" className="pc" />
                <img src={this.state.bannerListObj["RIGHT"]["mobileImgFilePath"]} alt="선수소개, 기아티아이거즈의 호랑이 전사들!" className="tb" />
              </Link>
            </div>

            {/* 트위터 */}
            {this.state.snsInfo["twitter"] ? (
              <div className="mainTw">
                {/* <a target="_blank" rel="noopener noreferrer" href={this.state.snsInfo.twitter.url}> */}
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Kiatigers">
                  {/* <img src="/img/main/twitter-img.png" alt="트위터 연결" className="pc" /> */}
                  {/* <img src="/img/main/a_tigers07.png" alt="트위터 연결" className="pc" /> */}
                  {/* <img src="/img/main/a_tigers08.png" alt="트위터 연결" className="pc" />
                                    <img src="/img/main/a_tigers07_mb.png" alt="트위터 연결" className="tb" /> */}
                  <img src="/img/main/2025_X_pc.png" alt="트위터 연결" className="pc" />
                  <img src="/img/main/2025_X_mobile.png" alt="트위터 연결" className="tb" />
                  <dl>
                    {/* <dt>
                      <img src="/img/main/main_at_twt.png" alt="트위터 로고" />
                    </dt> */}
                    {/* <dd>{this.state.snsInfo.twitter.text}</dd> */}
                    {/* <dd>www.twitter.com</dd>
                                    <dd>2019.10.02&nbsp;&nbsp;  13:30</dd> */}
                  </dl>
                </a>
              </div>
            ) : null}

            {/* 인스타그램 */}
            {this.state.snsInfo["instagram"] ? (
              <div className="mainInst">
                <a target="_blank" rel="noopener noreferrer" href={this.state.snsInfo.instagram.url}>
                  {/* <img
                    src={this.state.snsInfo.instagram.image}
                    alt="인스타그램 연결"
                    className="pc"
                  />
                  <img
                    src={this.state.snsInfo.instagram.image}
                    alt="인스타그램 연결"
                    className="tb"
                  />
                  <dl>
                  <dt>
                  <img
                  src="/img/main/main_at_insta.png"
                  alt="인스타그램 로고"
                  />
                  </dt>
                  <dd>{this.state.snsInfo.instagram.text}</dd>
                </dl> */}
                  <img src="/img/main/2025_instagram_pc.png" alt="인스타그램 연결" className="pc" />
                  <img src="/img/main/2025_instagram_mobile.png" alt="인스타그램 연결" className="tb" />
                </a>
              </div>
            ) : null}

            {/* 유튜브 */}
            {this.state.snsInfo["youtube"] ? (
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src={this.state.snsInfo.youtube.url}
                  frameBorder="0"
                  title={this.state.snsInfo.text}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
          </section>
          <SlidePartnerComponent
            list={this.state.sponsorList}
            setSliderRef={this.setSliderRef}
            onClickBtnSlidePrev={this.onClickBtnSlidePrev}
            onClickBtnSlideNext={this.onClickBtnSlideNext}
          />
        </div>
      ) : null}
      {/* <!-- //contIn --> */}
      {this.state.popupContentList.length !== 0 ? (
        <LayerPopupComponent popup={this.state.popup} popupContentList={this.state.popupContentList} closePopup={this.closePopup} />
      ) : null}

      {/* 팝업 레이어 클래스 on 추가 시 팝업 [쿠키 24시간으로 설정 후 개발 해야함]*/}

      {/* <div className={"pop " + (this.state.popup ? "on" : "")}>
        <div className="pop_overlay" />
        <div
          className="popupIn"
          style={{ left: window.innerWidth / 2 - 200, top: "150px" }}
        >
          <a
            href="https://www.hmsec.com/board/view.do?board_name=event_board&mbr_id=17146"
            target="_blank"
            rel="noopener"
          >
            <img
              src="/popup/image.png"
              alt="현대차 증권과 함께하는 프로야구 이벤트"
              width="400"
            />
          </a>
          <div className="popupClose" onClick={() => this.closePopup()}>
            오늘 그만 보기
          </div>
        </div>
      </div> */}
    </div>
    // <!-- //container -->
  )
}

export default withRouter(HomeContainer)
