import React from "react"

import * as CommonHelper from "../../../helpers/Common.helper"
import PrepareComponent from '../../layout/Prepare.component'

const ContComponent = (props) => (
  <div className="mb_cont ticket">
    <div className="mb_contIn reserve sesonTKet">
      {process.env.REACT_APP_PREPARING_SEASON_TICKET_PAGE_YN === "Y" ? null : CommonHelper.checkIsOnWebview() ? null : (
        <div className="ticketBox">
          <span>KIA타이거즈</span>
          <strong>시즌권 안내</strong>
        </div>
      )}
      {props.imgUrlObj ? (
        <p className="img">
          <img src={props.imgUrlObj.pc} alt="" className="pc_tb" />
          <img src={props.imgUrlObj.m} alt="" className="mb" />
        </p>
      ) : null}
      {process.env.REACT_APP_PREPARING_SEASON_TICKET_PAGE_YN === "N" && !CommonHelper.checkIsOnWebview() && (
        <div className={"appDown"}>
          <button className="mb" onClick={props.onClickBtnRedirectStore} type="button">
            구매하기
          </button>
        </div>
      )}
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
)

export default ContComponent
