import React from "react";

const ContComponent = (props) => (
  <div className="mb_cont tigers">
    <div className="mb_contIn sponsor">
      {/* <a href="https://kiatigersad.co.kr/work" target="_blank"> */}
      <a href={props.sponsorLink[0]} target="_blank">
        <div className="vs">
          <div className="pc">
          </div>
          <img
            src="/img/sub/banner_sponsor_25_.png"
            alt="스폰서 PC이미지"
            className="pc"
          />
        </div>
      </a>
      <article>
        <h5 className="lineRed">공식파트너</h5>
        <ul className="clear">
          {props.authorizedList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <article>
        <h5 className="lineRed">킷 스폰서</h5>
        <ul className="clear">
          {props.kitList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <article>
        <h5 className="lineRed">프리미엄 스폰서</h5>
        <ul className="clear">
          {props.premiumList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <article>
        <h5 className="lineRed">오피셜 스폰서</h5>
        <ul className="clear">
          {props.officialList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <article>
        <h5 className="lineRed">제휴카드</h5>
        <ul className="clear">
          {props.collaboratedList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <article>
        <h5 className="lineRed">지정병원</h5>
        <ul className="clear">
          {props.hospitalList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <article>
        <h5 className="lineRed">공식 공급업체</h5>
        <ul className="clear">
          {props.supportList.map((item, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                <img src={item.imgFilePath} alt={item.artcTitle} />
                <span>{item.artcTitle}</span>
              </a>
            </li>
          ))}
        </ul>
      </article>
      <div className="vs mb tb" style={{marginTop:'25px'}}>
        <a href="https://kiatigersad.co.kr/" target="_blank">
          <img
            // src="/img/sub/back-img_2021_mb.png"
            src="/img/sub/banner_sponsor_25_.png"

            alt="스폰서 mobile이미지"
            className="tb"
          />
        </a>
      </div>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
