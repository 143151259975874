import React from "react";

import Slider from "react-slick";
import * as ImageHelper from "../../helpers/Image.helper";

const options = {
  autoplay: true,
  speed: 300,
  arrows: false,
  infinite: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
};

const SlideGameComponent = (props) => (
  <div className="m0auto main_sl2">
    <section className="vs_slide2">
      {/* vs info 주석 시작 */}
      <div className="sl2">
        <Slider
          ref={(slider) => props.setSliderRef("slide-game", slider)}
          {...options}
        >
          {props.list.map((item, idx) => (
            <div className="sl2_sq clear" key={idx}>
              <p>
                {item.displayDate.substr(0, 4)}.{item.displayDate.substr(4, 2)}.
                {item.displayDate.substr(6, 2)}({item.gweek})
              </p>
              <p>
                {item.stadium}
                {item.gtime}
              </p>
              <ul className="main_gameSc clear">
                <li className="main_gm_sc main_gm_scL">
                  <strong>{item.visitScore}</strong>
                  <img
                    src={ImageHelper.getEmblemImgByTeamCode(item.visitKey)}
                    alt={item.visitFullname}
                  />
                  <span>{item.visit}</span>
                </li>
                <li className="main_gm_vs">vs</li>
                <li className="main_gm_sc main_gm_scR">
                  <span>{item.home}</span>
                  <img
                    src={ImageHelper.getEmblemImgByTeamCode(item.homeKey)}
                    alt={item.homeFullname}
                  />
                  <strong>{item.homeScore}</strong>
                </li>
              </ul>
              <p className="main_gameover">{item.statusStr}</p>
            </div>
          ))}
        </Slider>
      </div>
      <p
        onClick={(evt) => props.onClickBtnSlidePrev(evt, "slide-game")}
        className="sl2_prev"
      >
        <img src="./img/main/sl2_prev.png" alt="이전" />
      </p>
      <p
        onClick={(evt) => props.onClickBtnSlideNext(evt, "slide-game")}
        className="sl2_next"
      >
        <img src="./img/main/sl2_next.png" alt="다음" />
      </p>
      {/* vs info 주석 끝 */}
    </section>
  </div>
);

export default SlideGameComponent;
