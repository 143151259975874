import React from "react";
import { Link } from "react-router-dom";
import { decodeAES256 } from "../../../helpers/Crypto.helper";

const ContComponent = props => (
  <header>
    <div className="headWrap">
      <div id="skipNavi">
        <a href="#container">본문바로가기</a>
      </div>
      <div className="topM pc">
        {props.loginInfo ? (
          <ul className="topIn">
            <li>
              <a href="https://teamstore.tigers.co.kr" style={{color:"#ea0029"}}>
                팀스토어
              </a>
            </li>
            <li>
              <a href="#" onClick={props.onClickBtnLogout}>
                로그아웃
              </a>
            </li>
            <li>
              <Link to="/mypage">마이페이지</Link>
            </li>
            <li>{decodeAES256(props.loginInfo.user.simpleId)} 님 환영합니다.</li>
          </ul>
        ) : (
          <ul className="topIn">
            <li>
              <a href="https://teamstore.tigers.co.kr" style={{color:"#ea0029"}}>
                팀스토어
              </a>
            </li>
            <li>
              <Link to={"/signup"}>회원가입</Link>
            </li>
            <li>
              <Link to={"/login"}>로그인</Link>
            </li>
          </ul>
        )}
      </div>
      {/* <!-- //topM --> */}
      <div className="mb_topM tb">
        <ul className="mb_topIn">
          <li className="btn_mbMenu">
            <a>
              <img src="/img/main/mb_menu.png" alt="메뉴버튼" />
            </a>
          </li>
          <li className="btn_mbLogin">
            <a>
              <img src="/img/main/mb_login.png" alt="로그인" />
            </a>
          </li>
        </ul>
      </div>
      {/* <!-- gnb --> */}
      <h1 className="mainLogo">
        <Link to={"/"}>
          <span className="blind">본문바로가기</span>
        </Link>
      </h1>
      <nav>
        <div className="navBg"></div>
        <div className="gnb pc">
          <ul>
            <li
              className={props.gnbFocusingIdx === 1 ? "act" : ""}
              onMouseOver={evt => props.onMouseOverGnb(evt, 1)}
              onMouseLeave={evt => props.onMouseLeaveGnb(evt, 1)}
            >
              <Link to="/tigers/greeting">TIGERS</Link>
            </li>
            <li
              className={props.gnbFocusingIdx === 2 ? "act" : ""}
              onMouseOver={evt => props.onMouseOverGnb(evt, 2)}
              onMouseLeave={evt => props.onMouseLeaveGnb(evt, 2)}
            >
              <Link to="/players/coaching-staff">PLAYERS</Link>
            </li>
            <li
              className={props.gnbFocusingIdx === 3 ? "act" : ""}
              onMouseOver={evt => props.onMouseOverGnb(evt, 3)}
              onMouseLeave={evt => props.onMouseLeaveGnb(evt, 3)}
            >
              <Link to="/contents/press">CONTENTS</Link>
            </li>
            <li
              className={props.gnbFocusingIdx === 4 ? "act" : ""}
              onMouseOver={evt => props.onMouseOverGnb(evt, 4)}
              onMouseLeave={evt => props.onMouseLeaveGnb(evt, 4)}
            >
              <Link to="/game/schedule">GAME</Link>
            </li>
            <li
              className={props.gnbFocusingIdx === 5 ? "act" : ""}
              onMouseOver={evt => props.onMouseOverGnb(evt, 5)}
              onMouseLeave={evt => props.onMouseLeaveGnb(evt, 5)}
            >
              <Link to="/ticket/reservation">TICKET</Link>
            </li>
            <li
              className={props.gnbFocusingIdx === 6 ? "act" : ""}
              onMouseOver={evt => props.onMouseOverGnb(evt, 6)}
              onMouseLeave={evt => props.onMouseLeaveGnb(evt, 6)}
            >
              <Link to="/point/guide">MEMBERSHIP</Link>
            </li>
          </ul>
          <div
            className={(props.isOpenedGnb ? "on" : "") + " gnbIn"}
            onMouseOver={props.onMouseOverGnbIn}
            onMouseLeave={props.onMouseLeaveGnbIn}
          >
            <div className="gnbInIn">
              <dl>
                <dt className="blind">TIGERS</dt>
                <dd>
                  <Link to="/tigers/greeting">인사말</Link>
                </dd>
                <dd>
                  <Link to="/tigers/intro">구단소개</Link>
                </dd>
                <dd>
                  <Link to="/tigers/champions-field">
                    광주-기아 챔피언스 필드
                  </Link>
                </dd>
                <dd>
                  <Link to="/tigers/challengers-field">기아 챌린저스 필드</Link>
                </dd>
                <dd>
                  <Link to="/tigers/bi">CI</Link>
                </dd>
                <dd>
                  <a target="_blank" rel="noopener noreferrer" href="/history"
                     onClick={() => {
                       props.onClickHistorySite();
                     }}>
                    역사관
                  </a>
                </dd>
                {/* <dd>
                  <Link to="/tigers/organization">조직도</Link>
                </dd> */}
                <dd>
                  <Link to="/tigers/cheering-party">응원단</Link>
                </dd>
                <dd>
                  <Link to="/tigers/sponsor">스폰서</Link>
                </dd>
              </dl>
              <dl>
                <dt className="blind">PLAYERS</dt>
                <dd>
                  <Link to="/players/coaching-staff">코칭스태프</Link>
                </dd>
                <dd>
                  <Link to="/players/pitcher">투수</Link>
                </dd>
                <dd>
                  <Link to="/players/catcher">포수</Link>
                </dd>
                <dd>
                  <Link to="/players/infielder">내야수</Link>
                </dd>
                <dd>
                  <Link to="/players/outfielder">외야수</Link>
                </dd>
                <dd>
                  <Link to="/players/military-enlisted">군입대선수</Link>
                </dd>
                <dd>
                  <Link to="/players/entry-status">엔트리현황</Link>
                </dd>
              </dl>
              <dl>
                <dt className="blind">CONTENTS</dt>
                <dd>
                  <Link to="/contents/notice">공지사항</Link>
                </dd>
                <dd>
                  <Link to="/contents/press">보도자료</Link>
                </dd>
                <dd>
                  <Link to="/contents/news">타이거즈 뉴스</Link>
                </dd>
                <dd>
                  <Link to="/game/pictures">타이거즈 포토</Link>
                </dd>
                {/* <dd>
                  <Link to="/contents/webzine">타이거즈 웹진</Link>
                </dd> */}
                <dd>
                  <Link to="/contents/tv">타이거즈TV</Link>
                </dd>
                {/* <dd>
                  <Link to="/contents/episode">타이거즈 에피소드</Link>
                </dd> */}
                <dd>
                  <Link to="/contents/sns">구단SNS</Link>
                </dd>
                <dd>
                  <Link to="/contents/archive">지난 콘텐츠 보기</Link>
                </dd>
              </dl>
              <dl>
                <dt className="blind">GAME</dt>
                <dd>
                  <Link to="/game/schedule">경기일정/결과</Link>
                </dd>
                <dd>
                  <Link to="/game/baseball">Tigers Baseball</Link>
                </dd>
                {/* <dd>
                  <Link to="/game/pictures">생생화보</Link>
                </dd> */}
                <dd>
                  <Link to="/game/stats">STATS</Link>
                </dd>
              </dl>
              <dl>
                <dt className="blind">TICKET</dt>
                <dd>
                  <Link to="/ticket/reservation">티켓 안내 및 예매</Link>
                </dd>
                <dd>
                  <Link to="/ticket/season-pass">시즌권 안내</Link>
                </dd>
                {/* 2025 시즌 주석 시작 */}
                {/* <dd>
                  <Link to="/ticket/skybox">
                    스카이박스 시즌권
                    <br />
                    안내
                  </Link>
                </dd> */}
                {/* 2025 시즌 주석 끝 */}
              </dl>
              <dl>
                <dt className="blind">MEMBERSHIP</dt>
                <dd>
                  <Link to="/point/guide">포인트 제도 안내</Link>
                </dd>
                <dd>
                  <Link to="/membership/tigers">호랑이 사랑방</Link>
                </dd>
                <dd>
                  <Link to="/membership/faq">FAQ</Link>
                </dd>
                <dd>
                  <Link to="/membership/inquiry">1:1 문의</Link>
                </dd>
                {/* <dd><Link to="/membership/event">온라인 이벤트</Link></dd> */}
              </dl>
            </div>
            {/* <!-- //gnbInIn --> */}
          </div>
          {/* <!-- //gnbIn --> */}
        </div>
        {/* <!-- // gnb --> */}
      </nav>
      <div className="tb">
        <div className="menuBg"></div>
        <p className="btn_mbMenu_close">
          <img src="/img/main/mb_close.png" alt="모바일 메뉴 닫기버튼" />
        </p>
        <div className="mb_gnb">
          <div>
            <h2>
              <Link to="/">
                <img src="/img/main/mb_menu_logo.png" alt="KIA TIGERS 로고" />
              </Link>
            </h2>
          </div>
          <ul className="mb_gnbIn">
            <li className="">
              <Link to="/tigers/greeting">TIGERS</Link>
              <div>
                <p>
                  <Link to="/tigers/greeting">인사말</Link>
                </p>
                <p>
                  <Link to="/tigers/intro">구단소개</Link>
                </p>
                <p>
                  <Link to="/tigers/champions-field">
                    광주-기아 챔피언스 필드
                  </Link>
                </p>
                <p>
                  <Link to="/tigers/challengers-field">기아 챌린저스 필드</Link>
                </p>
                <p>
                  <Link to="/tigers/bi">CI</Link>
                </p>
                <p>
                  <a target="_blank" rel="noopener noreferrer" href="/history" onClick={() => {

                  }}>
                    역사관
                  </a>
                </p>
                {/* <p>
                  <Link to="/tigers/organization">조직도</Link>
                </p> */}
                <p>
                  <Link to="/tigers/cheering-party">응원단</Link>
                </p>
                <p>
                  <Link to="/tigers/sponsor">스폰서</Link>
                </p>
              </div>
            </li>
            <li>
              <Link to="/players/coaching-staff">PLAYERS</Link>
              <div>
                <p>
                  <Link to="/players/coaching-staff">코칭스태프</Link>
                </p>
                <p>
                  <Link to="/players/pitcher">투수</Link>
                </p>
                <p>
                  <Link to="/players/catcher">포수</Link>
                </p>
                <p>
                  <Link to="/players/infielder">내야수</Link>
                </p>
                <p>
                  <Link to="/players/outfielder">외야수</Link>
                </p>
                <p>
                  <Link to="/players/military-enlisted">군입대선수</Link>
                </p>
                <p>
                  <Link to="/players/entry-status">엔트리현황</Link>
                </p>
              </div>
            </li>
            <li>
              <Link to="/contents/press">CONTENTS</Link>
              <div>
                <p>
                  <Link to="/contents/notice">공지사항</Link>
                </p>
                <p>
                  <Link to="/contents/press">보도자료</Link>
                </p>
                <p>
                  <Link to="/contents/news">타이거즈 뉴스</Link>
                </p>
                <p>
                  <Link to="/game/pictures">타이거즈 포토</Link>
                </p>
                {/* <p>
                  <Link to="/contents/webzine">타이거즈 웹진</Link>
                </p> */}
                <p>
                  <Link to="/contents/tv">타이거즈TV</Link>
                </p>
                {/* <p>
                  <Link to="/contents/episode">타이거즈 에피소드</Link>
                </p> */}
                <p>
                  <Link to="/contents/sns">구단SNS</Link>
                </p>
                <p>
                  <Link to="/contents/archive">지난 콘텐츠 보기</Link>
                </p>
              </div>
            </li>
            <li>
              <Link to="/game/schedule">GAME</Link>
              <div>
                <p>
                  <Link to="/game/schedule">경기일정/결과</Link>
                </p>
                <p>
                  <Link to="/game/baseball">Tigers Baseball</Link>
                </p>
                {/* <p>
                  <Link to="/game/pictures">생생화보</Link>
                </p> */}
                <p>
                  <Link to="/game/stats">STATS</Link>
                </p>
              </div>
            </li>
            <li>
              <Link to="/ticket/reservation">TICKET</Link>
              <div>
                <p>
                  <Link to="/ticket/reservation">티켓 안내 및 예매</Link>
                </p>
                <p>
                  <Link to="/ticket/season-pass">시즌권 안내</Link>
                </p>
                {/* 2025 시즌 주석 시작 */}
                {/* <p>
                  <Link to="/ticket/skybox">스카이박스 시즌권 안내</Link>
                </p> */}
                {/* 2025 시즌 주석 끝 */}
              </div>
            </li>
            <li>
              <Link to="/point/guide">TIGERS MEMBERSHIP</Link>
              <div>
                <p>
                  <Link to="/point/guide">포인트 제도 안내</Link>
                </p>
                <p>
                  <Link to="/membership/tigers">호랑이 사랑방</Link>
                </p>
                <p>
                  <Link to="/membership/faq">FAQ</Link>
                </p>
                <p>
                  <Link to="/membership/inquiry">1:1 문의</Link>
                </p>
                {/* <p><Link to="/membership/event">온라인 이벤트</Link></p> */}
              </div>
            </li>
            {/* <li className="mb_gnbBg"></li>*/}
          </ul>
        </div>

        {/* <!-- // mb_gnb --> */}
        {/* 우측 SNB */}
        <div className="mb_snb">
          <div>
            <h2>
              <Link to="/">
                <img src="/img/main/mb_menu_logo.png" alt="KIA TIGERS 로고" />
              </Link>
            </h2>
            <p className="btn_mbLogin_close">
              <img src="/img/main/mb_close.png" alt="모바일 로그인 닫기버튼" />
            </p>
            <div>
              {props.loginInfo ? (
                <div>
                  {/* 로그인 상태 */}
                  <p>
                    <strong>
                      {decodeAES256(props.loginInfo.user.memberName)}(
                      {decodeAES256(props.loginInfo.user.simpleId)})님,{" "}
                    </strong>
                    <span>방문을 환영합니다.</span>
                  </p>
                  <ul className="snb_btn">
                    <li>
                      <a href="#" onClick={props.onClickBtnLogout}>
                        <img src="/img/main/mb_logout.png" alt="로그아웃" />
                        로그아웃
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div>
                  <p>
                    로그인 후 더 다양한 <span>기아타이거즈를 만나보세요.</span>
                  </p>
                  <ul className="snb_btn">
                    <li>
                      <Link to="/login">
                        <img src="/img/main/mb_login2.png" alt="로그인" />
                        로그인
                      </Link>
                    </li>
                    <li>
                      <Link to="/signup">
                        <img src="/img/main/mb_join.png" alt="회원가입" />
                        회원가입
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          {props.loginInfo ? (
            <ul className="mb_snbIn">
              <li className="">
                <a href="">스마트 오더</a>
                <div>
                  {/* <p><Link to="/membership/smart-order">스마트 오더</Link></p> */}
                  <p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://teamstore.tigers.co.kr/"
                    >
                      팀스토어
                    </a>
                  </p>
                </div>
              </li>
              <li className="">
                <a href="">My Page</a>
                <div>
                  <p>
                    <Link to="/mypage">내 정보 관리</Link>
                  </p>
                </div>
              </li>
              {/* <!-- <li className="mb_snbBg"></li> --> */}
            </ul>
          ) : null}
        </div>
        {/* <!-- // mb_snb --> */}
      </div>
    </div>
    {/* <!-- // headWrap --> */}
  </header>
);

export default ContComponent;
