import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import * as CommonHelper from "../../../helpers/Common.helper";
import PrepareComponent from '../../layout/Prepare.component'
import dayjs from 'dayjs';

const zoomPanPinchOptions = {
  transformEnabled: true,
  centerContent: true,
};

const redirectYn = process.env.REACT_APP_REDIRECT_STORE_YN;

const ContComponent = (props) => {

  return(
  <div className="mb_cont ticket">
    <div className="mb_contIn reserve">
      {process.env.REACT_APP_PREPARING_TICKET_PAGE_YN ===
      "Y" ? null : !CommonHelper.checkIsOnWebview() ? (
        redirectYn === "Y" ? (
          <div className="ticketBox">
            <span>{dayjs().format('YYYY')}년 KIA타이거즈</span>
            <strong>티켓 안내 및 예매</strong>
            <button
              className="pc"
              onClick={props.onClickBtnReservation}
              type="button"
            >
              입장권 예매
            </button>
            <button
              className="tb"
              onClick={props.onClickBtnReservation}
              type="button"
            >
              입장권 예매
            </button>

            {/* 로그인 후 예매취소 버튼 시작 */}
            <button
              className="pc"
              onClick={props.onClickBtnReservation}
              type="button"
              style={{
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5px"
              }}
            >
              예매 취소
            </button>
            <button
              className="tb"
              onClick={props.onClickBtnReservation}
              type="button"
              style={{
                marginTop: "5px"
              }}
            >
              예매 취소
            </button>
           {/* 로그인 후 예매취소 버튼 끝 */}
            123
          </div>

        ) : (
          <div className="ticketBox">
            <span>{dayjs().format('YYYY')}년 KIA타이거즈</span>
            <strong>티켓 안내 및 예매</strong>
            <button
              className="pc"
              onClick={props.onClickBtnReservation}
              type="button"
            >
              입장권 예매
            </button>
            <button
              className="tb"
              onClick={props.onClickBtnReservation}
              type="button"
            >
              입장권 예매
            </button>
            {/* 로그인 전 예매취소 버튼 시작 */}
            <button
              className="pc"
              onClick={props.onClickBtnCancelReservation}
              type="button"
              style={{
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5px"
              }}
            >
              예매 취소
            </button>
            <button
              className="tb"
              onClick={props.onClickBtnCancelReservation}
              type="button"
              style={{
                marginTop: "5px"
              }}
            >
              예매 취소
            </button>
           {/* 로그인 전 예매취소 버튼 끝 */}
            <p>- 권종선택 실수로 인한 예매는 교환/환불 불가합니다.</p>
            <p>- 주차장이 협소하므로 대중교통 이용을 부탁드립니다.</p>
          </div>
        )
      ) : null}
      {/* {process.env.REACT_APP_PREPARING_TICKET_PAGE_YN === "Y" ? null : (
        <div className="ticketMapWrap">
          <p>광주-기아 챔피언스 필드</p>
          <div
            className="ticketMap"
            style={{ height: props.imgObj.ticketMapHeight }}
          >
            {props.imgUrlObj ? (
              <TransformWrapper defaultScale={1} options={zoomPanPinchOptions}>
                <TransformComponent>
                  <img src={props.imgUrlObj.stadium} alt="test" />
                </TransformComponent>
              </TransformWrapper>
            ) : null}
          </div>
          <p className="pc_tb ticketTxt">
            <strong>면적_</strong>57,646㎥(620,496ft²)
            <strong>규모_</strong>지하2층 지상5층
            <strong>관람석 수_</strong>20,500석
            <strong>경기장 규모_</strong>좌/우 99M, 중 121M Left/Right 325ft,
            Center 400ft
          </p>
        </div>
      )} */}

      {props.imgUrlObj ? (
        <p className="img">
          {/* <img src="/img/sub/reserve_img_mb_00.png" alt="면적, 규모, 관람석 수, 경기장 규모" className="ticketMapImg mb" /> */}
          <img src={props.imgUrlObj.pc} alt="" className="pc_tb" />
          <img src={props.imgUrlObj.m} alt="" className="mb" />
        </p>
      ) : null}
      {CommonHelper.checkIsOnWebview() ? (
        props.imgUrlObj ? (
          <p className="img">
            <img src={props.imgUrlObj.seasonPassPc} alt="" className="pc_tb" />
            <img src={props.imgUrlObj.seasonPassM} alt="" className="mb" />
          </p>
        ) : null
      ) : null}
    </div>
  </div>
)}

export default ContComponent;
