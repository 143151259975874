import React from "react";

import SubMenuComponent from "../../common/SubMenu.component";

import { Line } from "react-chartjs-2";

import ImgComponent from "../../../common/Img.component";
import * as ImageHelper from "../../../../helpers/Image.helper";

import PlayerListSliderComponent from "../../common/info/PlayerListSlider.component";

import NewsListSliderComponent from "../../common/info/NewsListSlider.component";
import GalleryListSliderComponent from "../../common/info/GalleryListSlider.component";
import DetailProfileComponent from "../../common/info/DetailProfile.component";

import MatchTeamRecordComponent from "../../common/info/MatchTeamRecord.component";
import MonthlyRecordComponent from "../../common/info/MonthlyRecord.component";
import WeeklyRecordComponent from "../../common/info/WeeklyRecord.component";
import DayNightRecordComponent from "../../common/info/DayNightRecord.component";
import HomeAwayRecordComponent from "../../common/info/HomeAwayRecord.component.js";

import BestDefenceRateComponent from "../../common/info/BestDefenceRate.component.js";
import BestHitRateComponent from "../../common/info/BestHitRate.component";
import BestWinComponent from "../../common/info/BestWin.component";
import BestHitComponent from "../../common/info/BestHit.component";
import BestHoldComponent from "../../common/info/BestHold.component";
import BestHitPointComponent from "../../common/info/BestHitPoint.component";
import BestSaveComponent from "../../common/info/BestSave.component";
import BestHomeRunComponent from "../../common/info/BestHomeRun.component";
import BestWinningRateComponent from "../../common/info/BestWinningRate.component";
import BestKkComponent from "../../common/info/BestKk.component";
import BestBraComponent from "../../common/info/BestBra.component";
import BestSbComponent from "../../common/info/BestSb.component";
import YearRecordListComponent from "../../common/info/YearRecordList.component";
import SeasonAllGameComponent from "../../common/info/SeasonAllGameComponent";
import GalleryPopupContainer from "../../common/info/GalleryPopup.container";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont player pitcher">
    <div className="mb_contIn">
      <SubMenuComponent
        subMenu={props.subMenu}
        subMenuName={props.subMenuName}
      />
      <PlayerListSliderComponent
        list={props.playerList}
        setSliderRef={props.setSliderRef}
        playerCode={props.playerCode}
        onClickBtnPlayer={props.onClickBtnPlayer}
        onClickBtnSlidePrev={props.onClickBtnSlidePrev}
        onClickBtnSlideNext={props.onClickBtnSlideNext}
        uri={props.uri}
      />
    </div>
    <section className="plyr_view01">
      <h6 className="blind">프로필</h6>
      {props.info ? (
        <div className="plyrVs">
          <div className="plyrImg">
            <p>
              <img src={props.info.playerProfileImg} alt="선수사진" />
            </p>
          </div>
          <div>
            <div className="plyr_view01In">
              <p>
                {props.info.position} / {props.info.hittype}
              </p>
              <h5>
                <strong>{props.info.backnum}</strong> {props.info.playerName}
              </h5>
              <dl>
                <dt>생년월일</dt>
                <dd>
                  {props.info.birth.substr(0, 4) + "년"}&nbsp;
                  {props.info.birth.substr(4, 2) + "월"}&nbsp;
                  {props.info.birth.substr(6, 2) + "일"}&nbsp;
                </dd>
                <dt>프로입단</dt>
                <dd>
                  {props.info.indate.substr(0, 4) + "년"}&nbsp;
                  {/*{props.info.indate.substr(4, 2) + "월"}&nbsp;*/}
                  {/* {props.info.indate.substr(6, 2) + "일"}&nbsp; */}
                  &nbsp;
                </dd>
                <dt>신장/체중</dt>
                {/* <dd>{props.info.height} / {props.info.weight}</dd> */}
                <dd>{props.info.heightWeight}&nbsp;</dd>
                <dt>
                출신교<br />및 경력
                </dt>
                <dd>{props.info.career}&nbsp;</dd>
              </dl>
              { props.info.army_yn === "N" ? (
              <dl>
                {/* <dt>입단연도</dt>
                                <dd>{props.info.gyear}</dd> */}
                {/* <dt>계약금/연봉</dt> */}
                <dt>연봉</dt>
                <dd className="money">
                  {/* {props.info.promise
                    ? props.info.promise
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "-"}
                  <br className="pc" />
                  /&nbsp; */}
                  {props.info.money
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </dd>
              </dl>
                ) : (
              <dl>
                <dt>전역예정일</dt>
                <dd>
                  {props.info.armyment
                    ? props.info.armyment
                    : "-"}
                </dd>
              </dl>
                )}
            </div>
            {props.info.position2 === "투" ? (
                <div className="plyrScore">
                  <ul>
                    <li>
                      <span>ERA</span>
                      <strong>{(props.summaryInfo && props.summaryInfo.ERA) || '-'}</strong>
                    </li>
                    <li>
                      <span>승</span>
                      <strong>{(props.summaryInfo && props.summaryInfo.W) || '-'}</strong>
                    </li>
                    <li>
                      <span>패</span>
                      <strong>{(props.summaryInfo && props.summaryInfo.L) || '-'}</strong>
                    </li>
                    <li>
                      <span>세이브</span>
                      <strong>{(props.summaryInfo && props.summaryInfo.SV) || '-'}</strong>
                    </li>
                    <li>
                      <span>탈삼진</span>
                      <strong>{(props.summaryInfo && props.summaryInfo.KK) || '-'}</strong>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="plyrScore">
                  <ul>
                    <li>
                      <span>경기</span>
                      <strong>{(props.summaryInfo[0] && props.summaryInfo[0].GAMENUM) || '-'}</strong>
                    </li>
                    <li>
                      <span>안타</span>
                      <strong>{(props.summaryInfo[0] && props.summaryInfo[0].HIT) || '-'}</strong>
                    </li>
                    <li>
                      <span>홈런</span>
                      <strong>{(props.summaryInfo[0] && props.summaryInfo[0].HR) || '-'}</strong>
                    </li>
                    <li>
                      <span>타점</span>
                      <strong>{(props.summaryInfo[0] && props.summaryInfo[0].RBI) || '-'}</strong>
                    </li>
                    <li>
                      <span>타율</span>
                      <strong>{(props.summaryInfo[0] && props.summaryInfo[0].HRA) || '-'}</strong>
                    </li>
                  </ul>
                </div>
              )}
          </div>
        </div>
      ) : null}
    </section>
    {props.info ? (
      <section className="view_tab view_tab01 pc">
        <h6 className="blind">탭메뉴</h6>
        {/* 뉴스 리스트  */}
        <div className="view_tabIn">
          <dl className={props.subTabNum === 1 ? "on" : ""}>
            <dt
              className={props.subTabNum === 1 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 1)}
            >
              NEWS
            </dt>
            <dd>
              {props.newsList.length === 0 ? (
                <div className="empty_photo news">
                  <h1>자료가 없습니다.</h1>
                </div>
              ) : (
                <div>
                  <ul className="news" style={{ marginLeft: "22px" }}>
                    <NewsListSliderComponent {...props} />
                  </ul>
                  {props.newsList.length > 4 && (
                    <div>
                      <p
                        className="news_prev"
                        onClick={(evt) =>
                          props.onClickBtnSlidePrev(evt, "news")
                        }
                      >
                        <img
                          src="/img/sub/view_prev.png"
                          alt="슬라이드 이전버튼"
                        />
                      </p>
                      <p
                        className="news_next"
                        onClick={(evt) =>
                          props.onClickBtnSlideNext(evt, "news")
                        }
                      >
                        <img
                          src="/img/sub/view_next.png"
                          alt="슬라이드 다음버튼"
                        />
                      </p>
                    </div>
                  )}
                </div>
              )}
            </dd>
          </dl>
          <dl className={props.subTabNum === 2 ? "on" : ""}>
            <dt
              className={props.subTabNum === 2 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 2)}
            >
              GALLERY
            </dt>
            <dd>
              {props.galleryList.length === 0 ? (
                <div className="empty_photo gallery pc">
                  <h1>자료가 없습니다.</h1>
                </div>
              ) : (
                <div>
                  <ul className="gallery" style={{ marginLeft: "22px" }}>
                    <GalleryListSliderComponent {...props} />
                  </ul>
                  {props.galleryList.length > 4 && (
                    <div>
                      <p
                        className="gallery_prev"
                        onClick={(evt) =>
                          props.onClickBtnSlidePrev(evt, "gallery")
                        }
                      >
                        <img
                          src="/img/sub/view_prev.png"
                          alt="슬라이드 이전버튼"
                        />
                      </p>
                      <p
                        className="gallery_next"
                        onClick={(evt) =>
                          props.onClickBtnSlideNext(evt, "gallery")
                        }
                      >
                        <img
                          src="/img/sub/view_next.png"
                          alt="슬라이드 다음튼"
                        />
                      </p>
                    </div>
                  )}
                </div>
              )}
            </dd>
          </dl>
          <DetailProfileComponent {...props} />
        </div>
      </section>
    ) : null}
    {props.info ? (
      <section className="view_tab view_tab01 pc">
        <div className="srch">
          <p
            className="btn_srch"
            style={{ cursor: "pointer" }}
            onClick={props.onClickBtnSearch}
          >
            검색
          </p>
          <p>
            <label htmlFor="cell" className="blind">
              년도
            </label>
            <select
              id="cell"
              value={props.selectedYear}
              onChange={props.onChangeSelectList}
            >
              {props.yearSelectList.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.text}시즌
                </option>
              ))}
            </select>
          </p>
        </div>

        {/* 컨디션 기록 시작  */}
        <h6 className="blind">컨디션기록 & 상세기록 TAB MENU</h6>
        <div
          className="view_tabIn view_tab02 condi"
          style={{ height: props.thirdTabHeight }}
        >
          <dl className={props.thirdTabNum === 1 ? "on" : ""}>
            <dt
              onClick={(evt) => props.onClickBtnThirdTab(evt, 1)}
              className={props.thirdTabNum === 1 ? "act" : ""}
            >
              컨디션기록
            </dt>
            <dd>
              <div className="conditn">
                <dl className="on">
                  <dt
                    onClick={(evt) => props.onClickBtnGraphTab(evt, 1)}
                    className={props.graphTabNum === 1 ? "act" : ""}
                  >
                    {props.info.position2 == "투" ? "방어율" : "타율"}
                  </dt>
                  <dd>
                    <div className="conditnBox">
                      <Line
                        width={1202}
                        height={420}
                        legend={false}
                        data={props.monthlyGraphData}
                      />
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt
                    onClick={(evt) => props.onClickBtnGraphTab(evt, 2)}
                    className={props.graphTabNum === 2 ? "act" : ""}
                  >
                    {props.info.position2 == "투" ? "승리" : "안타"}
                  </dt>
                  <dd>
                    <div className="conditnBox">승리</div>
                  </dd>
                </dl>
                <dl>
                  <dt
                    onClick={(evt) => props.onClickBtnGraphTab(evt, 3)}
                    className={props.graphTabNum === 3 ? "act" : ""}
                  >
                    {props.info.position2 == "투" ? "홀드" : "홈런"}
                  </dt>
                  <dd>
                    <div className="conditnBox">홀드</div>
                  </dd>
                </dl>
                <dl>
                  <dt
                    onClick={(evt) => props.onClickBtnGraphTab(evt, 4)}
                    className={props.graphTabNum === 4 ? "act" : ""}
                  >
                    {props.info.position2 == "투" ? "세이브" : "타점"}
                  </dt>
                  <dd>
                    <div className="conditnBox">세이브</div>
                  </dd>
                </dl>
                <dl>
                  <dt
                    onClick={(evt) => props.onClickBtnGraphTab(evt, 5)}
                    className={props.graphTabNum === 5 ? "act" : ""}
                  >
                    {props.info.position2 == "투" ? "승률" : "출루율"}
                  </dt>
                  <dd>
                    <div className="conditnBox">승률</div>
                  </dd>
                </dl>
                <dl>
                  <dt
                    onClick={(evt) => props.onClickBtnGraphTab(evt, 6)}
                    className={props.graphTabNum === 6 ? "act" : ""}
                  >
                    {props.info.position2 == "투" ? "탈삼진" : "도루"}
                  </dt>
                  <dd>
                    <div className="conditnBox">탈삼진</div>
                  </dd>
                </dl>
              </div>

              {/* 네번 째 탭  */}
              <div className="tabInTab view_tabIn tabInTab6 condiMax">
                {/* 최고방어율 / 최고타율 */}
                <dl className={props.fourthTabNum === 1 ? "on" : ""}>
                  <dt
                    className={props.fourthTabNum === 1 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFourthTab(evt, 1)}
                  >
                    {props.info.position2 === "투" ? "최고방어율" : "최고타율"}
                  </dt>
                  <dd>
                    {props.bestEraObj ? (
                      <BestDefenceRateComponent {...props} />
                    ) : null}
                    {props.bestHraObj ? (
                      <BestHitRateComponent {...props} />
                    ) : null}
                  </dd>
                </dl>

                {/* 최다승리 / 최다안타 */}
                <dl className={props.fourthTabNum === 2 ? "on" : ""}>
                  <dt
                    style={{ left: "16.666%" }}
                    className={props.fourthTabNum === 2 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFourthTab(evt, 2)}
                  >
                    {props.info.position2 === "투" ? "최다승리" : "최다안타"}
                  </dt>
                  <dd>
                    {props.bestWinObj ? <BestWinComponent {...props} /> : null}
                    {props.bestHitObj ? <BestHitComponent {...props} /> : null}
                  </dd>
                </dl>

                {/* 최다홀드 / 최다홈런 */}
                <dl className={props.fourthTabNum === 3 ? "on" : ""}>
                  <dt
                    style={{ left: "33.332%" }}
                    className={props.fourthTabNum === 3 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFourthTab(evt, 3)}
                  >
                    {props.info.position2 === "투" ? "최다홀드" : "최다홈런"}
                  </dt>
                  <dd>
                    {props.bestHoldObj ? (
                      <BestHoldComponent {...props} />
                    ) : null}
                    {props.bestHrObj ? (
                      <BestHomeRunComponent {...props} />
                    ) : null}
                  </dd>
                </dl>

                {/* 최다세이브 / 최다타점 */}
                <dl className={props.fourthTabNum === 4 ? "on" : ""}>
                  <dt
                    style={{ left: "49.998%" }}
                    className={props.fourthTabNum === 4 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFourthTab(evt, 4)}
                  >
                    {props.info.position2 === "투" ? "최다세이브" : "최다타점"}
                  </dt>
                  <dd>
                    {props.bestSaveObj ? (
                      <BestSaveComponent {...props} />
                    ) : null}
                    {props.bestRbiObj ? (
                      <BestHitPointComponent {...props} />
                    ) : null}
                  </dd>
                </dl>
                {/* 최고승률 / 최고출루율 */}
                <dl className={props.fourthTabNum === 5 ? "on" : ""}>
                  <dt
                    style={{ left: "66.664%" }}
                    className={props.fourthTabNum === 5 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFourthTab(evt, 5)}
                  >
                    {props.info.position2 === "투" ? "최고승률" : "최고출루율"}
                  </dt>
                  <dd>
                    {props.bestWraObj ? (
                      <BestWinningRateComponent {...props} />
                    ) : null}
                    {props.bestBraObj ? <BestBraComponent {...props} /> : null}
                  </dd>
                </dl>

                {/* 최다탈삼진 / 최다도루 */}
                <dl className={props.fourthTabNum === 6 ? "on" : ""}>
                  <dt
                    style={{ left: "83.33%" }}
                    className={props.fourthTabNum === 6 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFourthTab(evt, 6)}
                  >
                    {props.info.position2 === "투" ? "최다탈삼진" : "최다도루"}
                  </dt>
                  <dd>
                    {props.bestKkObj ? <BestKkComponent {...props} /> : null}
                    {props.bestSbObj ? <BestSbComponent {...props} /> : null}
                  </dd>
                </dl>
              </div>
              <div
                className="tabInTab view_tabIn tabInTab5 categMonDate divHeight"
                style={{ height: props.fifthTabHeight }}
              >
                <dl className={props.fifthTabNum === 1 ? "on" : ""}>
                  <dt
                    className={props.fifthTabNum === 1 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFifthTab(evt, 1)}
                  >
                    상대팀별
                  </dt>
                  <dd>
                    <div className="table_Typ01 tableW19">
                      <MatchTeamRecordComponent {...props} />
                    </div>
                  </dd>
                </dl>
                <dl className={props.fifthTabNum === 2 ? "on" : ""}>
                  <dt
                    style={{ left: "20%" }}
                    className={props.fifthTabNum === 2 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFifthTab(evt, 2)}
                  >
                    월별
                  </dt>
                  <dd>
                    <div className="table_Typ01 tableW19">
                      <MonthlyRecordComponent {...props} />
                    </div>
                  </dd>
                </dl>
                <dl className={props.fifthTabNum === 3 ? "on" : ""}>
                  <dt
                    style={{ left: "40%" }}
                    className={props.fifthTabNum === 3 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFifthTab(evt, 3)}
                  >
                    요일별
                  </dt>
                  <dd>
                    <div className="table_Typ01 tableW19">
                      <WeeklyRecordComponent {...props} />
                    </div>
                  </dd>
                </dl>
                <dl className={props.fifthTabNum === 4 ? "on" : ""}>
                  <dt
                    style={{ left: "60%" }}
                    className={props.fifthTabNum === 4 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFifthTab(evt, 4)}
                  >
                    주/야간별
                  </dt>
                  <dd>
                    <div className="table_Typ01 tableW19">
                      <DayNightRecordComponent {...props} />
                    </div>
                  </dd>
                </dl>
                <dl className={props.fifthTabNum === 5 ? "on" : ""}>
                  <dt
                    style={{ left: "80%" }}
                    className={props.fifthTabNum === 5 ? "act" : ""}
                    onClick={(evt) => props.onClickBtnFifthTab(evt, 5)}
                  >
                    홈/원정별
                  </dt>
                  <dd>
                    <div className="table_Typ01 tableW19">
                      <HomeAwayRecordComponent {...props} />
                    </div>
                  </dd>
                </dl>
              </div>
            </dd>
          </dl>
          <dl className={props.thirdTabNum === 2 ? "on detailW" : "detailW"}>
            <dt
              onClick={(evt) => props.onClickBtnThirdTab(evt, 2)}
              className={props.thirdTabNum === 2 ? "act" : ""}
            >
              상세기록
            </dt>
            <dd>
              <h6>통산기록</h6>
              <div className="table_Typ01 tableW19 ">
                <YearRecordListComponent {...props} />
              </div>
              <h6>상세기록</h6>
              <div className="table_Typ01 tableW19 ">
                <SeasonAllGameComponent {...props} />
              </div>
            </dd>
          </dl>
        </div>
      </section>
    ) : null}

    {/* 모바일 페이지 데이터 */}
    {props.info ? (
      <section className="view_tab view_tab01 tb mbTabTable">
        <h6 className="blind">mobile TAB MENU (선수기록, NEWS, GALLERY)</h6>
        <div
          className="viewBtn view_tabIn viewTabLitt divHeight"
          style={{ height: props.subtabTableHeight }}
        >
          <dl className={props.subTabNum === 1 ? "on" : ""}>
            <dt
              className={props.subTabNum === 1 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 1)}
            >
              상세기록
            </dt>
            <dd>
              {/* 상대팀별 데이터 */}
              <div className="mbTableWrap">
                <h6>상대팀별</h6>
                <div className="mbTable mb">
                  <ul>
                    <li>상대</li>
                    {props.matchTeamRecordList.map((item, idx) => (
                      <li key={idx}>{item.groupIf}</li>
                    ))}
                    <li className="tFoot">합계</li>
                  </ul>
                </div>
                <div className="table_Typ01 mbScroll tableW19">
                  <MatchTeamRecordComponent {...props} />
                </div>
              </div>

              {/* 월별 데이터 */}
              <div className="mbTableWrap">
                <h6>월별 기록</h6>
                <div className="mbTable mb">
                  <ul>
                    <li>월별</li>
                    {props.monthlyRecordList.map((item, idx) => (
                      <li key={idx}>{item.groupIf}</li>
                    ))}
                    <li className="tFoot">합계</li>
                  </ul>
                </div>
                <div className="table_Typ01 mbScroll tableW19">
                  <MonthlyRecordComponent {...props} />
                </div>
              </div>

              {/* 요일별 데이터 */}
              <div className="mbTableWrap">
                <h6>요일별 기록</h6>
                <div className="mbTable mb">
                  <ul>
                    <li>요일별</li>
                    {props.weeklyRecordList.map((item, idx) => (
                      <li key={idx}>{item.groupIf}</li>
                    ))}
                    <li className="tFoot">합계</li>
                  </ul>
                </div>
                <div className="table_Typ01 mbScroll tableW19">
                  <WeeklyRecordComponent {...props} />
                </div>
              </div>
              {/* <!-- //mbTableWrap --> */}
              <div className="mbTableWrap">
                <h6>주/야간별 기록</h6>
                <div className="mbTable mb">
                  <ul>
                    <li>주/야간</li>
                    {props.dayNightRecordList.map((item, idx) => (
                      <li key={idx}>{item.groupIf}</li>
                    ))}
                    <li className="tFoot">합계</li>
                  </ul>
                </div>
                <div className="table_Typ01 mbScroll tableW19">
                  <DayNightRecordComponent {...props} />
                </div>
              </div>
              {/* <!-- //mbTableWrap --> */}
              <div className="mbTableWrap">
                <h6>홈/원정별 기록</h6>
                <div className="mbTable mb">
                  <ul>
                    <li>홈/원정</li>
                    {props.homeAwayRecordList.map((item, idx) => (
                      <li key={idx}>{item.groupIf}</li>
                    ))}
                    <li className="tFoot">합계</li>
                  </ul>
                </div>
                <div className="table_Typ01 mbScroll tableW19">
                  <HomeAwayRecordComponent {...props} />
                </div>
              </div>
              {/* <!-- //mbTableWrap --> */}
            </dd>
          </dl>
          <dl className={props.subTabNum === 2 ? "tb on" : "tb"}>
            <dt
              className={props.subTabNum === 2 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 2)}
            >
              NEWS
            </dt>
            <dd>
              <ul className="mb_news">
                {props.newsList.length === 0 ? (
                  <div className="empty_photo_m news">
                    <h1>자료가 없습니다.</h1>
                  </div>
                ) : (
                  props.newsList.map((item, idx) =>
                    idx < 3 ? (
                      <li
                        key={idx}
                        onClick={(evt) =>
                          props.onClickBtnNews(evt, item.artcSeq)
                        }
                      >
                        <div className="img">
                          <ImgComponent
                            imgUrl={item.imgFilePath}
                            defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                            imgName={item.artcTitle}
                          />
                        </div>
                        <div>
                          <p>{item.artcTitle}</p>
                          <p>{item.contents}</p>
                          <p>
                            <span className="date">{item.regDate}</span>
                            <span className="num">{item.viewCnt}</span>
                          </p>
                        </div>
                      </li>
                    ) : null
                  )
                )}
              </ul>
            </dd>
          </dl>
          <dl className={props.subTabNum === 3 ? "tb on" : "tb"}>
            <dt
              className={props.subTabNum === 3 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 3)}
            >
              GALLERY
            </dt>
            <dd>
              <ul className="mb_gallery clear">
                {props.galleryList.length === 0 ? (
                  <h1 className="empty_photo_m">자료가 없습니다.</h1>
                ) : (
                  props.galleryList.map((item, idx) =>
                    idx < 4 ? (
                      <li
                        key={idx}
                        onClick={(evt) =>
                          props.onClickBtnPicture(evt, item.name)
                        }
                      >
                        <p>
                          <ImgComponent
                            imgUrl={item.path}
                            defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                            imgName={item.artcSubTitle}
                          />
                        </p>
                      </li>
                    ) : null
                  )
                )}
              </ul>
            </dd>
          </dl>
        </div>
      </section>
    ) : null}
    {/* <GalleryPopupContainer {...props} /> */}
    {props.selectedPopupContent ? <GalleryPopupContainer {...props} /> : null}
  </div>
)

export default ContComponent;
