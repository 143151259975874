import React, { Component } from 'react';
import SubHeadComponent from '../common/SubHead.component';
import ContComponent from './Cont.component';

import * as ResourceService from '../../../services/Resource.service';
import AnalyticsContext from "../../../context/AnalyticsContext"
import * as CommonService from "../../../services/Common.service"

class GreetingContainer extends Component {
    static contextType = AnalyticsContext;

    constructor(props) {
        super(props);

        const subMenu = 'cheering-party';
        const subMenuName = '응원단 소개'
        this.state = {
            subMenu,
            subMenuName,
            imgUrlObj: null
        }
    }

    componentDidMount = async _ => {
        const currentAnalyticsVal = this.context.state
        const prevUrl = currentAnalyticsVal.currentUrl;
        this.context.action(currentAnalyticsVal);

        currentAnalyticsVal.deps2 = "01";
        currentAnalyticsVal.deps3 = "07";
        currentAnalyticsVal.prevUrl = prevUrl;
        currentAnalyticsVal.currentUrl = window.location.href;

        await CommonService.insertAnalytics(this.context.state);

        const pcResult = await ResourceService.getCheerPartyImg();
        //const mResult = await ResourceService.getCheerPartyImg(false);

        const imgUrlObj = {
            pc: pcResult.resource.value,
            m: pcResult.resource.value
        };

        this.setState({ imgUrlObj });
    }

    render = () => (
      <div className='container'>
          <SubHeadComponent
            subMenuName={this.state.subMenuName}
            thirdMenuName={this.state.thirdMenuName} />
          <div className="sbHead_appView tb"></div>
          {process.env.REACT_APP_PREPARING_CHEER_PAGE_YN === "Y" ? (
            <div className="mb_cont intro1">
                <div className="mb_contIn">
                    <div style={{marginTop: "50px"}}>
                        <img
                          src="/img/seasonstop_2024_pc.png"
                          alt="season_off_pc"
                          className="pc"
                        />
                        <img
                          src="/img/seasonstop_2024_pc.png"
                          className="mb"
                          style={{width: "100%"}}
                        />
                    </div>
                </div>
            </div>
          ) : (
            <ContComponent
              subMenu={this.state.subMenu}
              subMenuName={this.state.subMenuName}
              thirdMenu={this.state.thirdMenu}
              thirdMenuName={this.state.thirdMenuName}

              imgUrlObj={this.state.imgUrlObj}

              tabNum={this.state.tabNum}
              onClickBtnTab={this.onClickBtnTab}
            />
          )}
      </div>
    )
}

export default GreetingContainer;
